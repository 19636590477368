import { teamsStore } from '@/store'
import { Team } from '@tada-team/tdproto-ts'

export const getLastActiveTeam = (): Team | null => {
  let teams = teamsStore.getters.teams
  if (!teams || teams.length === 0) return null

  let lastActiveTeam: Team | null = null

  teams.some(team => {
    if (team.lastActive) {
      lastActiveTeam = team
      return true
    }
    return false
  })

  if (lastActiveTeam) return lastActiveTeam

  teams = teams.filter(team => {
    const { me } = team
    return me.lastActivity
  })

  if (teams.length === 0) return null

  teams = teams.sort((teamA, teamB) => {
    const { me: meA } = teamA
    const { me: meB } = teamB

    const a = meA.lastActivity
    const b = meB.lastActivity

    if (!a) return 1
    if (!b) return -1

    return a > b ? -1 : (a < b ? 1 : 0)
  })
  return teams[0]
}

export const groupTeams = () => {
  const own: Array<Team> = []
  const other: Array<Team> = []

  const teams = teamsStore.getters.teams
  teams.forEach(team => {
    const { me } = team
    if (!me) return

    me.status === 'owner'
      ? own.push(team)
      : other.push(team)
  })
  return { own, other }
}
