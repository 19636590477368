
















































import { Component, Vue, Prop } from 'vue-property-decorator'
import
store, {
  rootStore,
  teamsStore,
  uiSettingsStore,
  activeCallStore,
  callsStore,
  uiStore,
  meetingsStore,
  loginStore,
  chatImportStore,
} from '@/store'
import Socket from '@/ws'
import { getLastActiveTeam } from '@/utils/Teams'
import { defaultLogger, routerLogger } from '@/loggers'
import * as Push from '@/notifications'

@Component({
  components: {
    Modals: () => import('@/components/Modals/index.vue'),
    MainLoader: () => import('@/components/UI/MainLoader.vue'),
    TheCallAudio: () => import('@/components/Calls/ActiveCall/TheCallAudio.vue'),
    ActiveCall: () => import('@/components/Calls/ActiveCall/index.vue'),
    TheCallingWindow: () => import('@/components/Calls/TheCallingWindow.vue'),
    ReconnectionBar: () => import('@/components/Chat/ReconnectionBar.vue'),
    IconLogoGray: () => import('@/components/UI/icons/IconLogoGray.vue'),
    MainError: () => import('./Error.vue'),
    LeftDrawer: () => import('./LeftDrawer.vue'),
    RightDrawer: () => import('./RightDrawer.vue'),
    Header: () => import('./Header/index.vue'),
    TheMeetingMd: () => import('@/components/Meetings/TheMeetingMd/index.vue'),
    TheImportChatMd: () => import('@/components/Import/Chat/TheImportChatMd.vue'),
  },
})
export default class TheMain extends Vue {
  @Prop({
    default: false,
    required: false,
    type: Boolean,
  }) isHideRightBar!: boolean

  @Prop({
    default: false,
    required: false,
    type: Boolean,
  }) headless!: boolean

  @Prop({
    required: false,
    type: String,
  }) routeTeamId!: string

  @Prop({
    required: false,
    default: false,
    type: Boolean,
  }) isCalendar!: boolean

  private wasRedirectedToOnboarding = false

  created (): void {
    if (!this.teamId) {
      defaultLogger.warn(`Team [${this.team}] is not found.`)
      this.$router.push({ name: 'Teams' }).catch(e => {
        routerLogger.debug('Router warning CreateTeam: ', e)
      })
      this.wasRedirectedToOnboarding = true
      return
    } else if (!teamsStore.state.data[this.teamId]) {
      this.isError = true
      return
    }
    teamsStore.actions.initTeam(this.teamId)
    uiSettingsStore.actions.initTeam({ teamId: this.teamId })
    Socket.connect()
    Push.init()
  }

  /**
   * Need to be here in case of inviting by link with loggedIn = true state
   */
  mounted () {
    !this.wasRedirectedToOnboarding && loginStore.actions.tryJoinByInvite()
  }

  private isError = false

  get teamId (): string {
    if (this.routeTeamId) return this.routeTeamId

    return this.team?.uid ?? ''
  }

  get team () {
    let team
    // If the team has not yet been initialized there will be an exception
    try {
      team = teamsStore.getters.currentTeam
    } catch (e) {
      team = getLastActiveTeam()
    }

    return team
  }

  get isAppReady (): boolean {
    return rootStore.state.isAppReady
  }

  get canShowRightBarOnTaskDesk () {
    const instance = uiStore.getters.currentRightBarInstance

    return ['contact-profile', 'team-profile'].includes(instance)
  }

  get isShowRightBar () {
    return this.isHideRightBar
      ? this.canShowRightBarOnTaskDesk
      : !!uiStore.getters.currentRightBarInstance
  }

  get profile () {
    return store.getters.profile
  }

  get activeCall () {
    return activeCallStore.state.activeCall
  }

  /**
   * There is no design yet for displaying multiple incoming buzzing calls.
   * We start with displaying the earliest one given it's not already active.
   */
  get earliestBuzzingCall () {
    let incoming = Object.values(callsStore.state.incomingBuzzingCalls)
    const active = activeCallStore.state.activeCall
    if (active) {
      incoming = incoming.filter(c => c.jid !== active.jid)
    }
    return incoming[0]
  }

  get isMeetingDialogActive () {
    return meetingsStore.state.isMeetingDialogActive
  }

  get isChatImportDialogOpen () {
    return chatImportStore.state.isChatImportDialogOpen
  }
}
